<template>
    <div class="posts-loader" ref="root">
        <PostsFilter v-if="!hideFilter" :filter-view-mode="filterViewMode" :show-date-filter="true" :filter="filter as PaginatorFilter"></PostsFilter>

        <template v-if="paginator.posts.value?.length > 0">
            <template v-if="filterViewMode === 'tabs'">
                <div class="tabs__post__wrapper">
                    <div class="row">
                        <div class="col s12 l10 offset-l1">
                            <div v-for="post in paginator.posts.value" :key="post.id" v-html="post.content"></div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <MasonryGrid :watch="[paginator.posts.value]" class="post__overview">
                    <div v-for="post in paginator.posts.value" :key="post.id" v-html="post.content"></div>
                </MasonryGrid>
            </template>
        </template>
        <div v-else-if="paginator.loading.value">
            {{ $t('loading') }}
        </div>
        <div v-else-if="paginator.page.value < 0">
            {{ $t('no_posts') }}
        </div>
        <div v-if="paginator.posts.value.length !== 0" class="button__wrapper button--center">
            <button
                v-if="loadMore && !paginator.fetchedAll.value"
                class="load-more load-more-button button button--simple"
                @click="paginator.fetchPosts"
                :disabled="paginator.loading.value"
            >
                <span v-if="paginator.loading.value">{{ $t('loading') }}</span>
                <span v-else>{{ $t('load_more') }}</span>
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';
import { DiviTaxonomyDefinition, PaginatorFilter, usePaginator, usePaginatorFilter } from '@/js/vue/composition/paginator';
import MasonryGrid from '@/js/vue/components/MasonryGrid.vue';
import PostsFilter from '@/js/vue/components/Filter.vue';

const props = defineProps({
    taxonomies: {
        type: Array as PropType<Array<DiviTaxonomyDefinition | Array<DiviTaxonomyDefinition>>>,
        required: true,
    },
    loadMore: {
        type: Boolean,
        default: () => true,
    },
    hideFilter: {
        type: Boolean,
        default: () => false,
    },
    paginatorOptions: {
        type: Object,
        required: true,
    },
    filterViewMode: {
        type: String,
    },
});

const root = ref<HTMLElement>();

const paginator = usePaginator({
    ...(props.paginatorOptions as any),
    taxonomies: props.taxonomies,
    requestTermCounts: true,
    url: props.paginatorOptions.url + '&filterViewMode=' + props.filterViewMode,
    initialPage: -1,
    fetch: false,
    hideFilter: props.hideFilter,
});

const filter = usePaginatorFilter({
    paginator: paginator,
    scrollToElementOnFilterRestore: root,
    singleFilter: ['tabs', 'dropdown'].indexOf(props.filterViewMode) >= 0,
    requireFilter: props.filterViewMode === 'tabs',
});
</script>
